import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';

import {
  Box,
  Button,
  Icon,
  Modal,
  Progress,
  Tag,
  Typography,
} from '@hero-design/react';

import ProfileHealthTimeline from '../ProfileHealth/Timeline';
import { genProfileHealthContent } from '../ProfileHealth/utils';
import StatusComponent from '../StatusComponent';

import PreviewSmartMatchProfile from '../../SetupUserProfile/SmartMatchPreview';

type ProgressStatusProps = { profileStrengthScore: number };

const ProgressStatus = ({ profileStrengthScore }: ProgressStatusProps) => {
  const { data } = useSession();
  const { push } = useRouter();
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const { text, icon, tagIntent } =
    genProfileHealthContent(profileStrengthScore);

  return (
    <>
      <StatusComponent
        imageSrc="/assets/images/profile_strength.png"
        imageAltText="profile health icon"
        titleText={
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            Profile strength
            <Box
              onClick={() => setInfoModalOpen(true)}
              sx={{ cursor: 'pointer' }}
            >
              <Tag
                intent={tagIntent}
                text={
                  <>
                    {icon && <Icon icon={icon} sx={{ mr: 'xsmall' }} />}
                    {text.toUpperCase()}
                  </>
                }
                variant="outlined"
                sx={{ height: 'fit-content' }}
              />
            </Box>
          </Box>
        }
        subtitleText={
          <>
            The more detailed your profile, the better matches you&apos;ll
            receive. Finish filling out your profile now to stand out from the
            crowd.
            <Progress
              value={profileStrengthScore}
              sx={{ mt: 'small' }}
              withInfo={false}
              data-test-id="profile-health-progress"
            />
          </>
        }
        buttonProps={{
          variant: 'outlined',
          text: 'Preview profile',
          onClick: () => setPreviewModalOpen(true),
        }}
      />
      {infoModalOpen && (
        <Modal open>
          <Box m="large">
            <Typography.Title level={4} sx={{ display: 'flex' }}>
              <>
                Your profile health is {text.toLowerCase()}!
                <Modal.CloseButton
                  onClick={() => setInfoModalOpen(false)}
                  data-test-id="modal-close-button"
                />
              </>
            </Typography.Title>
            <Progress
              value={profileStrengthScore}
              sx={{ mt: 'small', mb: 'medium' }}
              withInfo={false}
              data-test-id="profile-health-modal-progress"
            />
            <Box mb="large">
              <ProfileHealthTimeline
                profileStrengthScore={profileStrengthScore}
              />
            </Box>
            <Button
              text="Complete profile"
              onClick={() => push(`/user/${data?.user?.friendlyId}`)}
            />
          </Box>
        </Modal>
      )}
      {previewModalOpen && (
        <PreviewSmartMatchProfile
          previewProfile={previewModalOpen}
          setPreviewProfile={setPreviewModalOpen}
        />
      )}
    </>
  );
};

export default ProgressStatus;
